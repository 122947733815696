import { _, date, dictionary, locale, time } from 'svelte-i18n';

import { browser } from '$app/environment';
import { DEFAULT_LANGUAGE } from '$lib/helpers/languages';
import en from '$lib/translations/lang/en.json';
import sr from '$lib/translations/lang/sr.json';

async function setupI18n({ withLocale: _locale } = { withLocale: DEFAULT_LANGUAGE }) {
  dictionary.set({
    en: en,
    sr: sr
  });

  browser ? locale.set(_locale) : locale.set(DEFAULT_LANGUAGE);
}

export { _, date, locale, setupI18n, time };
